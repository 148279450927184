/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 21번째 로그에서는 출퇴근, 애플 신제품 출시, 구글 Stadia 발표에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bikeseoul.com/"
  }, "서울자전거 따릉이 - 무인대여시스템")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://service.kakaomobility.com/kakaot/index.html"
  }, "카카오 T")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=ryRnyFHU-Uk"
  }, "카카오T 바이크 - 공유 전기 자전거 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%84%9C%EC%9A%B8_%EC%A7%80%ED%95%98%EC%B2%A0_7%ED%98%B8%EC%84%A0"
  }, "서울 지하철 7호선 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.shinbundang.co.kr/"
  }, "신분당선 (DX LINE) 홈페이지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hyundai.com/kr/ko/vehicles/kona-electric/price"
  }, "가격 - 코나 Electric - 전체모델 | 현대자동차")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://earth.google.com/web/data=CiQSIhIgYmU3N2ZmYzU0MTc1MTFlOGFlOGZkMzdkYTU5MmE0MmE"
  }, "Where on Google Earth is Carmen Sandiego?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2019/03/imac-gets-a-2x-performance-boost/"
  }, "2배 더 좋아진 성능의 iMac - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2019/03/all-new-ipad-air-and-ipad-mini-deliver-dramatic-power-and-capability/"
  }, "놀라운 성능과 기능을 담아 완전히 새로워진 iPad Air와 iPad mini - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=BeFnQrgtZ9k"
  }, "Google's Stadia Announcement at GDC 2019 in Under 14 Minutes - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gdconf.com/"
  }, "GDC | Game Developers Conference")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.xsplit.com/"
  }, "Live Stream and Record your Gaming | XSplit")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nvidia.com/en-us/design-visualization/grid-vpc-vapps/"
  }, "Virtual PC and Virtual Applications | NVIDIA GRID")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.playstation.com/en-us/games/mlb-the-show-18-ps4/"
  }, "MLB® The Show 18™ Game | PS4 - PlayStation")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
